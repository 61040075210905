:root {
  --primaryColor: hsl(129, 53%, 46%);
  --secundaryColor: hsl(210, 79%, 26%);
  --hoverColor: hsl(128, 73%, 66%);
  --paleGreen: hsl(116, 65%, 79%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --greyText: rgb(190, 190, 190);
  --bgColor: hsl(220, 10%, 94%);
  --inputColor: hsl(330, 12%, 97%);
  --textColor: hsl(240, 1%, 48%);
  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontSize: .938rem;
  --smallFontSize: .813rem;
  --smallestFontSize: .75rem;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  align-items: center;
  gap: 1.5rem;
}

html {
  font-size: 100%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.sideBar {
  background: var(--whiteColor);
  box-shadow: 1px 0px 4px var(--greyText);
  width: 13%;
  height: 100%;
  gap: 3rem;
  overflow: auto;
}
.sideBar .logoDiv {
  padding: 1rem 3.5rem 0;
  cursor: pointer;
}
.sideBar .logoDiv img {
  max-width: 150px;
  margin-right: 0.5rem;
  border-radius: 2px;
  padding: 0.2rem;
}
.sideBar .logoDiv h1 {
  font-size: var(--h1FontSize);
  color: var(--primaryColor);
}
.sideBar .menuDiv .divTitle, .sideBar .configDiv .divTitle {
  font-size: var(--h3FontSize);
  color: var(--blackColor);
  padding: 0 2rem 1.5rem;
}
.sideBar .menuDiv .menuLists .listsItem, .sideBar .configDiv .menuLists .listsItem {
  padding: 0.1rem 2rem;
  width: 100%;
  position: relative;
}
.sideBar .menuDiv .menuLists .listsItem::before, .sideBar .configDiv .menuLists .listsItem::before {
  position: absolute;
  content: "";
  height: 0%;
  width: 5px;
  left: 0;
  bottom: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: var(--primaryColor);
}
.sideBar .menuDiv .menuLists .listsItem .menuLink, .sideBar .configDiv .menuLists .listsItem .menuLink {
  color: var(--greyText);
  font-weight: 500;
  transition: 0;
}
.sideBar .menuDiv .menuLists .listsItem .menuLink .icon, .sideBar .configDiv .menuLists .listsItem .menuLink .icon {
  margin-right: 0.5rem;
}
.sideBar .menuDiv .menuLists .listsItem:hover .menuLink, .sideBar .configDiv .menuLists .listsItem:hover .menuLink {
  color: var(--primaryColor);
  transition: 0;
}
.sideBar .menuDiv .menuLists .listsItem:hover::before, .sideBar .configDiv .menuLists .listsItem:hover::before {
  height: 100%;
  transition: 0.3s ease;
}
.sideBar .sideBarCard {
  width: 100%;
  padding: 1rem;
  text-align: center;
  position: relative;
}
.sideBar .sideBarCard .icon {
  position: absolute;
  background: var(--hoverColor);
  border: 10px solid var(--whiteColor);
  font-size: 3rem;
  border-radius: 50%;
  top: -8px;
  right: 50%;
  transform: translate(50%);
  z-index: 100;
}
.sideBar .sideBarCard .cardContent {
  position: relative;
  padding: 1rem;
  background: var(--hoverColor);
  border-radius: 10px;
  overflow: hidden;
}
.sideBar .sideBarCard .cardContent h3 {
  font-size: var(--h3FontSize);
  margin-top: 1rem;
  padding: 1rem 0;
  font-weight: 800;
  color: var(--blackColor);
}
.sideBar .sideBarCard .cardContent p {
  font-size: var(--normalFontSize);
  color: var(--textColor);
  padding-bottom: 1rem;
  font-weight: 500;
}
.sideBar .sideBarCard .cardContent .btn {
  position: relative;
  color: var(--textColor);
  z-index: 1000;
  background: var(--whiteColor);
}
.sideBar .sideBarCard .cardContent .btn:hover {
  background: var(--paleGreen);
  color: var(--blackColor);
}
.sideBar .sideBarCard .cardContent .circle1, .sideBar .sideBarCard .cardContent .circle2 {
  position: absolute;
  background: var(--paleGreen);
  border-radius: 50%;
  opacity: 0.7;
}
.sideBar .sideBarCard .cardContent .circle1 {
  height: 100px;
  width: 100px;
  top: -50px;
  left: -40%;
}
.sideBar .sideBarCard .cardContent .circle2 {
  height: 150px;
  width: 100px;
  bottom: -80px;
  right: -40%;
  z-index: 1;
}

@media screen and (max-width: 678px) {
  .sideBar {
    background: var(--whiteColor);
    box-shadow: 1px 0px 4px var(--greyText);
    width: 10%;
    height: 100%;
    gap: 3rem;
    overflow: auto;
  }
  .sideBar .menuDiv {
    padding: 0;
  }
  .sideBar .menuDiv .divTitle {
    font-size: 1px;
  }
  .sideBar .menuDiv .menuLists .listsItem {
    padding: 0.6rem 0.2rem;
    width: 100%;
  }
  .sideBar .menuDiv .menuLists .listsItem .menuLink {
    left: 0;
  }
  .sideBar .menuDiv .menuLists .listsItem .menuLink .icon {
    margin-left: 0;
    font-size: 30px;
    left: 0;
    justify-content: left;
  }
  .sideBarCard {
    width: 40%;
    padding: 6rem;
    position: absolute;
  }
}/*# sourceMappingURL=index.css.map */