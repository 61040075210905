@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    transition: .1s ease;
    
    
}

    body, .loginPage, .registerPage{
        background:var(--secundaryColor);
    }
    
    
    
  

:root{
    --primaryColor: hsl(129, 53%, 46%);
    --secundaryColor: hsl(210, 79%, 26%);
    --hoverColor: hsl(128, 73%, 66%);
    --paleGreen: hsl(116, 65%, 79%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --greyText: rgb(190, 190, 190);
    --bgColor: hsl(220, 10%, 94%);
    --inputColor: hsl(330, 12%, 97%);
    --textColor: hsl(240, 1%, 48%);
   


    --biggestFontSize: 2.5rem;
    --h1FontSize: 1.5rem;
    --h2FontSize: 1.25rem;
    --h3FontSize: 1rem;
    --normalFontSize: .938rem;
    --smallFontSize: .813rem;
    --smallestFontSize: .75rem;



}

.flex{
    display: flex;
    align-items: center;
}

.grid{
    display: grid;
    align-items: center;
    gap: 1.5rem;
}

html{
    font-size: 90%;
}

a{
    text-decoration: none;
}

li{
    list-style: none;

}

.icon{
    font-size: var(--h2FontSize);
    cursor: pointer;
}

img{
    height: auto;
    width: 100%;
}

.flex{
    display: flex;
    align-items: center;
}

.grid{
    display: grid;
    align-items: center;
}

.message{
    display: none;
}


.showMessage{
    display: block;
    color: var(--whiteColor);
    background: rgb(3, 67, 120);
    border-radius: 3px;
    text-align: center;
    padding: .1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: bold;
    max-width: 330px;
}

.btn{
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    padding: .8rem 1rem;
    color: var(--textColor);
}

.btn .icon{
    margin-left: .5rem;
}

.btn:hover{
    color: var(--primaryColor);
    background: var(--paleGreen);
}

.dashboard{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 1rem;
    background: var(--secundaryColor);
    
   

    .dashboardContainer{
        position: relative;
        width: 95vw;
        height: 95vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bgColor);
        overflow: hidden;
        border-radius: 1rem;
        box-shadow: 2px 2px 8px var(--greyText);
    }
}






.loginPage, .registerPage{
    height: 100vh;
    width: 100%;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 7px 50px rgb(214, 223, 213);
    


    .container{
        height: 75vh;
        width: 60%;
        margin: auto;
        justify-content: space-between;
        border-radius: 10px;
        background: hsl(0, 0%, 91%);


        .imageDiv{
        position: relative;
        display: flex;
        flex-basis: 50%;
        width: 70%;
        height: 100%;
        padding: 0 1.5rem;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        border-radius: 10px;
        overflow: hidden;
        background-color:var(--whiteColor);
    
        .footerDiv {
            position: absolute;
        }
        
        img {
            height: 60%;
            width: 60%;
            object-fit:cover;
            display:block; 
            margin: auto;
            align-items: center;
            
            
            
            
    
        }
        .textDiv{
            position: relative;
    
            .title{
                color:var(--whiteColor);
                font-size: 35px;
                font-weight: 800;
            }
            p{
                color: var(--whiteColor);
                padding: 1rem;
                font-weight: 400;
            }
        }
    
        .footerDiv{
            bottom: 10px;
            padding: 0 1rem;
            height: 60px;
            background: rgba(3, 67, 120, 0.070);
            left: 10px;
            right: 10px;
            justify-content: space-between;
            backdrop-filter: blur(1px);
            border-radius: 10px;
    
            .text{
                color: var(--greyText);
            }
            .btn{
                background: var(--whiteColor);
                color: var(--primaryColor);
                padding: .8rem 1.5rem;
                border-radius: 10px;
                font-weight: 400;
                transition: .3s ease;
    
                &:hover{
                    background: var(--hoverColor);
                    color: var(--blackColor);
    
                }
            }
    
        }
        }

        .formDiv{
            flex-basis: 50%;
            margin: auto;
            flex-direction: column;
            gap: 0rem;
            transform: translateY(-1rem);

            .headerDiv{
                text-align: center;
                padding: 1rem 0;

                img{
                    width: 90px;
                    padding-bottom: .5rem;
                }

                h3{
                    font-size: 25px;
                    color: var(--blackColor);
                    font-weight: 700;

                }
            }

            .form{
                gap: .5rem;
                height: 100%;

                .inputDiv{
                    .icon{
                        color: var(--greyText);
                        .Dir{
                            position: relative;
                            display: flex;
                            justify-content: space-between;
                        }
                    }
                    input{
                        background: none;
                        outline: none;
                        border: none;
                        width: 200px;

                    }
                    label{
                        color: var(--blackColor);
                        font-weight: 400;
                        font-size: 14px;
                        padding: .2rem 0;
                        display: block;

                    }

                    .input{
                        gap: .5rem;
                        padding: .7rem;
                        background: var(--inputColor);
                        border-radius: 10px;
                    }
                }

                .btn{
                    padding: .8rem;
                    background: var(--primaryColor);
                    border-radius: 10px;
                    color: var(--whiteColor);
                    align-items: center;
                    margin: auto;
                    justify-content: center;
                    font-size: 16px;
                    width: 100%;
                    gap: .5rem;
                    transition: .3s ease;

                    .icon{
                        font-size: 25px;
                        transition: .3s ease;

                    }

                    &:hover{
                        background: var(--hoverColor);
                        color: var(--blackColor);
                    }

                    .icon{
                        color: var(--blackColor);
                        transform: translate(5px);
                    }

                }
            }

            .esqueceuSenha{
                font-size: 13px;
                color: var(--blackColor);
                opacity: .7;
                text-align: center;
                text-decoration: none;

                a{
                    text-decoration: none;
                    cursor: pointer;
                    opacity: 1;
                    color: var(--textColor);
                    font-weight: 500;
                }
            }
        }

    }
}

///Scroll Hide//////

::-webkit-scrollbar{
    display: none;
}


//////////////////




//////////Sidebar///////////////////////////









////////////////////////////////////////////////
 
////// Body ////////////////////////////////////////////////




//////////////////////////////////////////////
/// 


.powerBi{
    background: var(--whiteColor);
    border-radius: 1rem;
    h2{
        font-size: var(--h1FontSize);
        color: var(--blackColor);
        font-weight: 700;
        text-align: center;
    }
    p{
        font-size: var(--normalFontSize);
        color: var(--textColor);
        font-weight: 500;
    }
    embed{
        width: 75vw;
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        
    }
    
}
/*body{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 1rem;
}*/
/*.dashboard{
    height: 100%;
    width: 100%;
    justify-content: center;
    margin: auto;

    .dashboardContainer{
        height: 95vh;
        width: 95%;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 5px 10px rgb(194, 194, 194);
    }
}*/


//Media Queries

@media screen and (max-width: 500px) {
    .heading{
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    
}
@media screen and (max-width: 918px) {
    .heading{
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }
    
}
@media screen and (max-width: 990px) {
    .heading{
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    
}

// Queries Login e Registro

@media screen and (max-width: 602px) {
    .loginPage, .registerPage{
        .container{
            height: 100%;
            width: 100%;
            flex-direction: column;
            padding: 1.5rem;

            .imageDiv{
                width: 100%;
                .textDiv{
                    .title{
                        font-size: 20px;
                    }
                    p{
                        padding: 1rem;
                    }
                }

                .footerDiv{
                    .text{
                        font-size: 12px;
                    }

                    .btn{
                        padding: .4rem 1.5rem;
                    }
                    
                }
            }

            .formDiv{
                gap: 1rem;
                transform: translateY(-.5rem);

                .headerDiv{
                    padding: 1rem 0;

                    img{
                        width: 40px;
                    }
                    h3{
                        font-size: 20;
                    }
                }

                .form{
                    gap: .5rem;

                    .inputDiv{
                        input{
                            width: 100%;
                        }
                    }
                }
            }


        }
        
    }
    
}


@media screen and (max-width: 678px) {
    .loginPage, .registerPage{
        .container{
           .imageDiv{
            img{
                width: 100%;
            }
                
                .textDiv{
                    .title{
                        font-size: 25px;
                    }
                    p{
                        padding: 1rem;
                    }
                }

                .footerDiv{
                    .text{
                        font-size: 12px;
                    }

                    .btn{
                        padding: .8rem 1.5rem;
                    }
                    
                }
            }

            .formDiv{
             
                .headerDiv{
                    padding: 1.5rem 0;

                    h3{
                        font-size: 20;
                    }
                }

                .form{

                    .inputDiv{
                        input{
                            width: 100%;
                            max-width: 200px;
                        }
                    }
                }
            }


        }
        
    }
    
}


@media screen and (max-width: 927px) {
    .loginPage, .registerPage{
        .container{
            width: 85%;


        }
        
    }
    
}