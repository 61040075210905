:root {
  --primaryColor: hsl(129, 53%, 46%);
  --secundaryColor: hsl(210, 79%, 26%);
  --hoverColor: hsl(128, 73%, 66%);
  --paleGreen: hsl(116, 65%, 79%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --greyText: rgb(190, 190, 190);
  --bgColor: hsl(220, 10%, 94%);
  --inputColor: hsl(330, 12%, 97%);
  --textColor: hsl(240, 1%, 48%);
  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontSize: .938rem;
  --smallFontSize: .813rem;
  --smallestFontSize: .75rem;
}

.registerPageCom {
  border: none;
  height: 100%;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secundaryColor);
}
.registerPageCom .containerCom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.registerPageCom .containerCom img {
  width: 100%;
  max-width: 800px;
  box-shadow: 0 10px 2px rgb(214, 223, 213);
}

::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .registerPageCom .containerCom {
    width: 90%;
  }
}
@media screen and (max-width: 602px) {
  .registerPageCom .containerCalc {
    width: 100%;
    flex-direction: column;
    padding: 1.5rem;
  }
}
@media screen and (max-width: 678px) {
  .registerPageCom {
    width: 100vw;
    border-radius: 1px;
    overflow: hidden;
  }
  .registerPageCom .iconComB {
    font-size: 40px;
    transition: 0.3s ease;
    float: right;
    padding-right: 0.3rem;
    color: var(--secundaryColor);
    right: 0;
    top: 0;
    display: flex;
    position: absolute;
  }
  .registerPageCom .containerCom {
    width: 100vw;
    overflow: hidden;
    border-radius: 5px;
  }
  .registerPageCom .containerCom embed {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}
@media all and (display-mode: fullscreen) {
  embed {
    margin: 0;
    border: 5px solid black;
  }
}
@media screen and (max-width: 927px) {
  .registerPageCom .containerCom {
    width: 85%;
  }
}/*# sourceMappingURL=comercial.css.map */