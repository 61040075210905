@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  transition: 0.1s ease;
}

body, .loginPage, .registerPage {
  background: var(--secundaryColor);
}

:root {
  --primaryColor: hsl(129, 53%, 46%);
  --secundaryColor: hsl(210, 79%, 26%);
  --hoverColor: hsl(128, 73%, 66%);
  --paleGreen: hsl(116, 65%, 79%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --greyText: rgb(190, 190, 190);
  --bgColor: hsl(220, 10%, 94%);
  --inputColor: hsl(330, 12%, 97%);
  --textColor: hsl(240, 1%, 48%);
  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontSize: .938rem;
  --smallFontSize: .813rem;
  --smallestFontSize: .75rem;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  align-items: center;
  gap: 1.5rem;
}

html {
  font-size: 90%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.icon {
  font-size: var(--h2FontSize);
  cursor: pointer;
}

img {
  height: auto;
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  align-items: center;
}

.message {
  display: none;
}

.showMessage {
  display: block;
  color: var(--whiteColor);
  background: rgb(3, 67, 120);
  border-radius: 3px;
  text-align: center;
  padding: 0.1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: bold;
  max-width: 330px;
}

.btn {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  padding: 0.8rem 1rem;
  color: var(--textColor);
}

.btn .icon {
  margin-left: 0.5rem;
}

.btn:hover {
  color: var(--primaryColor);
  background: var(--paleGreen);
}

.dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 1rem;
  background: var(--secundaryColor);
}
.dashboard .dashboardContainer {
  position: relative;
  width: 95vw;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bgColor);
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 2px 2px 8px var(--greyText);
}

.loginPage, .registerPage {
  height: 100vh;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 7px 50px rgb(214, 223, 213);
}
.loginPage .container, .registerPage .container {
  height: 75vh;
  width: 60%;
  margin: auto;
  justify-content: space-between;
  border-radius: 10px;
  background: hsl(0, 0%, 91%);
}
.loginPage .container .imageDiv, .registerPage .container .imageDiv {
  position: relative;
  display: flex;
  flex-basis: 50%;
  width: 70%;
  height: 100%;
  padding: 0 1.5rem;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--whiteColor);
}
.loginPage .container .imageDiv .footerDiv, .registerPage .container .imageDiv .footerDiv {
  position: absolute;
}
.loginPage .container .imageDiv img, .registerPage .container .imageDiv img {
  height: 60%;
  width: 60%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  margin: auto;
  align-items: center;
}
.loginPage .container .imageDiv .textDiv, .registerPage .container .imageDiv .textDiv {
  position: relative;
}
.loginPage .container .imageDiv .textDiv .title, .registerPage .container .imageDiv .textDiv .title {
  color: var(--whiteColor);
  font-size: 35px;
  font-weight: 800;
}
.loginPage .container .imageDiv .textDiv p, .registerPage .container .imageDiv .textDiv p {
  color: var(--whiteColor);
  padding: 1rem;
  font-weight: 400;
}
.loginPage .container .imageDiv .footerDiv, .registerPage .container .imageDiv .footerDiv {
  bottom: 10px;
  padding: 0 1rem;
  height: 60px;
  background: rgba(3, 67, 120, 0.07);
  left: 10px;
  right: 10px;
  justify-content: space-between;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  border-radius: 10px;
}
.loginPage .container .imageDiv .footerDiv .text, .registerPage .container .imageDiv .footerDiv .text {
  color: var(--greyText);
}
.loginPage .container .imageDiv .footerDiv .btn, .registerPage .container .imageDiv .footerDiv .btn {
  background: var(--whiteColor);
  color: var(--primaryColor);
  padding: 0.8rem 1.5rem;
  border-radius: 10px;
  font-weight: 400;
  transition: 0.3s ease;
}
.loginPage .container .imageDiv .footerDiv .btn:hover, .registerPage .container .imageDiv .footerDiv .btn:hover {
  background: var(--hoverColor);
  color: var(--blackColor);
}
.loginPage .container .formDiv, .registerPage .container .formDiv {
  flex-basis: 50%;
  margin: auto;
  flex-direction: column;
  gap: 0rem;
  transform: translateY(-1rem);
}
.loginPage .container .formDiv .headerDiv, .registerPage .container .formDiv .headerDiv {
  text-align: center;
  padding: 1rem 0;
}
.loginPage .container .formDiv .headerDiv img, .registerPage .container .formDiv .headerDiv img {
  width: 90px;
  padding-bottom: 0.5rem;
}
.loginPage .container .formDiv .headerDiv h3, .registerPage .container .formDiv .headerDiv h3 {
  font-size: 25px;
  color: var(--blackColor);
  font-weight: 700;
}
.loginPage .container .formDiv .form, .registerPage .container .formDiv .form {
  gap: 0.5rem;
  height: 100%;
}
.loginPage .container .formDiv .form .inputDiv .icon, .registerPage .container .formDiv .form .inputDiv .icon {
  color: var(--greyText);
}
.loginPage .container .formDiv .form .inputDiv .icon .Dir, .registerPage .container .formDiv .form .inputDiv .icon .Dir {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.loginPage .container .formDiv .form .inputDiv input, .registerPage .container .formDiv .form .inputDiv input {
  background: none;
  outline: none;
  border: none;
  width: 200px;
}
.loginPage .container .formDiv .form .inputDiv label, .registerPage .container .formDiv .form .inputDiv label {
  color: var(--blackColor);
  font-weight: 400;
  font-size: 14px;
  padding: 0.2rem 0;
  display: block;
}
.loginPage .container .formDiv .form .inputDiv .input, .registerPage .container .formDiv .form .inputDiv .input {
  gap: 0.5rem;
  padding: 0.7rem;
  background: var(--inputColor);
  border-radius: 10px;
}
.loginPage .container .formDiv .form .btn, .registerPage .container .formDiv .form .btn {
  padding: 0.8rem;
  background: var(--primaryColor);
  border-radius: 10px;
  color: var(--whiteColor);
  align-items: center;
  margin: auto;
  justify-content: center;
  font-size: 16px;
  width: 100%;
  gap: 0.5rem;
  transition: 0.3s ease;
}
.loginPage .container .formDiv .form .btn .icon, .registerPage .container .formDiv .form .btn .icon {
  font-size: 25px;
  transition: 0.3s ease;
}
.loginPage .container .formDiv .form .btn:hover, .registerPage .container .formDiv .form .btn:hover {
  background: var(--hoverColor);
  color: var(--blackColor);
}
.loginPage .container .formDiv .form .btn .icon, .registerPage .container .formDiv .form .btn .icon {
  color: var(--blackColor);
  transform: translate(5px);
}
.loginPage .container .formDiv .esqueceuSenha, .registerPage .container .formDiv .esqueceuSenha {
  font-size: 13px;
  color: var(--blackColor);
  opacity: 0.7;
  text-align: center;
  text-decoration: none;
}
.loginPage .container .formDiv .esqueceuSenha a, .registerPage .container .formDiv .esqueceuSenha a {
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  color: var(--textColor);
  font-weight: 500;
}

::-webkit-scrollbar {
  display: none;
}

.powerBi {
  background: var(--whiteColor);
  border-radius: 1rem;
}
.powerBi h2 {
  font-size: var(--h1FontSize);
  color: var(--blackColor);
  font-weight: 700;
  text-align: center;
}
.powerBi p {
  font-size: var(--normalFontSize);
  color: var(--textColor);
  font-weight: 500;
}
.powerBi embed {
  width: 75vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

/*body{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 1rem;
}*/
/*.dashboard{
    height: 100%;
    width: 100%;
    justify-content: center;
    margin: auto;

    .dashboardContainer{
        height: 95vh;
        width: 95%;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 5px 10px rgb(194, 194, 194);
    }
}*/
@media screen and (max-width: 500px) {
  .heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
@media screen and (max-width: 918px) {
  .heading {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
}
@media screen and (max-width: 990px) {
  .heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
@media screen and (max-width: 602px) {
  .loginPage .container, .registerPage .container {
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 1.5rem;
  }
  .loginPage .container .imageDiv, .registerPage .container .imageDiv {
    width: 100%;
  }
  .loginPage .container .imageDiv .textDiv .title, .registerPage .container .imageDiv .textDiv .title {
    font-size: 20px;
  }
  .loginPage .container .imageDiv .textDiv p, .registerPage .container .imageDiv .textDiv p {
    padding: 1rem;
  }
  .loginPage .container .imageDiv .footerDiv .text, .registerPage .container .imageDiv .footerDiv .text {
    font-size: 12px;
  }
  .loginPage .container .imageDiv .footerDiv .btn, .registerPage .container .imageDiv .footerDiv .btn {
    padding: 0.4rem 1.5rem;
  }
  .loginPage .container .formDiv, .registerPage .container .formDiv {
    gap: 1rem;
    transform: translateY(-0.5rem);
  }
  .loginPage .container .formDiv .headerDiv, .registerPage .container .formDiv .headerDiv {
    padding: 1rem 0;
  }
  .loginPage .container .formDiv .headerDiv img, .registerPage .container .formDiv .headerDiv img {
    width: 40px;
  }
  .loginPage .container .formDiv .headerDiv h3, .registerPage .container .formDiv .headerDiv h3 {
    font-size: 20;
  }
  .loginPage .container .formDiv .form, .registerPage .container .formDiv .form {
    gap: 0.5rem;
  }
  .loginPage .container .formDiv .form .inputDiv input, .registerPage .container .formDiv .form .inputDiv input {
    width: 100%;
  }
}
@media screen and (max-width: 678px) {
  .loginPage .container .imageDiv img, .registerPage .container .imageDiv img {
    width: 100%;
  }
  .loginPage .container .imageDiv .textDiv .title, .registerPage .container .imageDiv .textDiv .title {
    font-size: 25px;
  }
  .loginPage .container .imageDiv .textDiv p, .registerPage .container .imageDiv .textDiv p {
    padding: 1rem;
  }
  .loginPage .container .imageDiv .footerDiv .text, .registerPage .container .imageDiv .footerDiv .text {
    font-size: 12px;
  }
  .loginPage .container .imageDiv .footerDiv .btn, .registerPage .container .imageDiv .footerDiv .btn {
    padding: 0.8rem 1.5rem;
  }
  .loginPage .container .formDiv .headerDiv, .registerPage .container .formDiv .headerDiv {
    padding: 1.5rem 0;
  }
  .loginPage .container .formDiv .headerDiv h3, .registerPage .container .formDiv .headerDiv h3 {
    font-size: 20;
  }
  .loginPage .container .formDiv .form .inputDiv input, .registerPage .container .formDiv .form .inputDiv input {
    width: 100%;
    max-width: 200px;
  }
}
@media screen and (max-width: 927px) {
  .loginPage .container, .registerPage .container {
    width: 85%;
  }
}/*# sourceMappingURL=App.css.map */