.inputDiv {
    display: flex;
    flex-direction: column;
    
}

.inputDiv .input input select {
    width: calc(100% - 30px);  
    
}
.inputDiv.full-width {
    grid-column: span 2;
}
.btnGrid {
    grid-column: span 2;
    
}
select{
    border: none;
    text-decoration: none;
    max-width: 200px;
    background-color: var(--inputColor);
}

.required{
    color:darkgrey;
    font-size: 12px;
}


@media screen and (max-width: 500px) {
    .inputDiv.full-width {
        grid-column: span 1;
    }
    .btnGrid {
        grid-column: span 1;
        
    }
    .formDiv{
        height: 500vw;
        overflow: auto;
    }
    

}