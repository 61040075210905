:root {
  --primaryColor: hsl(129, 53%, 46%);
  --secundaryColor: hsl(210, 79%, 26%);
  --hoverColor: hsl(128, 73%, 66%);
  --paleGreen: hsl(116, 65%, 79%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --greyText: rgb(190, 190, 190);
  --bgColor: hsl(220, 10%, 94%);
  --inputColor: hsl(330, 12%, 97%);
  --textColor: hsl(240, 1%, 48%);
  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontSize: .938rem;
  --smallFontSize: .813rem;
  --smallestFontSize: .75rem;
}

.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.inputDestaque {
  color: var(--primaryColor);
}

.inputBorder {
  border: 1px solid var(--primaryColor);
}

.inputCalc {
  justify-content: center;
  overflow: hidden;
}
.inputCalc input {
  font-size: var(--h3FontSize);
  font-weight: 800;
  text-align: center;
  overflow: hidden;
  display: block;
}
.inputCalc p {
  font-size: var(--h3FontSize);
  font-weight: 800;
  text-align: center;
  overflow: hidden;
  display: block;
}

.loginPageCalc, .registerPageCalc {
  height: 100%;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--secundaryColor);
}
.loginPageCalc .iconCalcB, .registerPageCalc .iconCalcB {
  font-size: 40px;
  transition: 0.3s ease;
  float: right;
  padding-right: 0.3rem;
  color: var(--secundaryColor);
  right: 0;
  top: 0;
  display: flex;
  position: absolute;
}
.loginPageCalc .containerCalc, .registerPageCalc .containerCalc {
  height: 100%;
  width: 60%;
  margin: auto;
  justify-content: space-between;
  border-radius: 10px;
  background: hsl(0, 0%, 91%);
  overflow: hidden;
}
.loginPageCalc .containerCalc .imageDivCalc, .registerPageCalc .containerCalc .imageDivCalc {
  position: relative;
  display: flex;
  flex-basis: 50%;
  width: 70%;
  height: 100%;
  padding: 0 1.5rem;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
}
.loginPageCalc .containerCalc .imageDivCalc img, .loginPageCalc .containerCalc .imageDivCalc .footerDivCalc, .registerPageCalc .containerCalc .imageDivCalc img, .registerPageCalc .containerCalc .imageDivCalc .footerDivCalc {
  position: absolute;
}
.loginPageCalc .containerCalc .imageDivCalc img, .registerPageCalc .containerCalc .imageDivCalc img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.loginPageCalc .containerCalc .imageDivCalc .textDivCalc, .registerPageCalc .containerCalc .imageDivCalc .textDivCalc {
  position: relative;
}
.loginPageCalc .containerCalc .imageDivCalc .textDivCalc .titleCalc, .registerPageCalc .containerCalc .imageDivCalc .textDivCalc .titleCalc {
  color: var(--whiteColor);
  font-size: 35px;
  font-weight: 800;
}
.loginPageCalc .containerCalc .imageDivCalc .textDivCalc p, .registerPageCalc .containerCalc .imageDivCalc .textDivCalc p {
  color: var(--whiteColor);
  padding: 1rem;
  font-weight: 400;
}
.loginPageCalc .containerCalc .imageDivCalc .footerDivCalc, .registerPageCalc .containerCalc .imageDivCalc .footerDivCalc {
  bottom: 10px;
  padding: 0 1rem;
  height: 60px;
  background: rgba(3, 67, 120, 0.07);
  left: 10px;
  right: 10px;
  justify-content: space-between;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  border-radius: 10px;
}
.loginPageCalc .containerCalc .imageDivCalc .footerDivCalc .textCalc, .registerPageCalc .containerCalc .imageDivCalc .footerDivCalc .textCalc {
  color: var(--greyText);
}
.loginPageCalc .containerCalc .imageDivCalc .footerDivCalc .btnCalc, .registerPageCalc .containerCalc .imageDivCalc .footerDivCalc .btnCalc {
  background: var(--whiteColor);
  color: var(--primaryColor);
  padding: 0.8rem 1.5rem;
  border-radius: 10px;
  font-weight: 400;
  transition: 0.3s ease;
  cursor: pointer;
}
.loginPageCalc .containerCalc .imageDivCalc .footerDivCalc .btnCalc:hover, .registerPageCalc .containerCalc .imageDivCalc .footerDivCalc .btnCalc:hover {
  background: var(--hoverColor);
  cursor: pointer;
}
.loginPageCalc .containerCalc .formDivCalc, .registerPageCalc .containerCalc .formDivCalc {
  flex-basis: 50%;
  margin: auto;
  flex-direction: column;
  gap: 1.5rem;
  transform: translateY(-1rem);
}
.loginPageCalc .containerCalc .formDivCalc .headerDivCalc, .registerPageCalc .containerCalc .formDivCalc .headerDivCalc {
  text-align: center;
  padding: 1.5rem 0;
}
.loginPageCalc .containerCalc .formDivCalc .headerDivCalc img, .registerPageCalc .containerCalc .formDivCalc .headerDivCalc img {
  width: 90px;
  padding-bottom: 0.5rem;
}
.loginPageCalc .containerCalc .formDivCalc .headerDivCalc h3, .registerPageCalc .containerCalc .formDivCalc .headerDivCalc h3 {
  font-size: 25px;
  color: var(--blackColor);
  font-weight: 700;
}
.loginPageCalc .containerCalc .formDivCalc .formCalc, .registerPageCalc .containerCalc .formDivCalc .formCalc {
  gap: 1rem;
}
.loginPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc .iconCalc, .registerPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc .iconCalc {
  color: var(--greyText);
}
.loginPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc input, .registerPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc input {
  background: none;
  outline: none;
  border: none;
  width: 300px;
}
.loginPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc label, .registerPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc label {
  color: var(--blackColor);
  font-weight: 400;
  font-size: 14px;
  padding: 0.5rem 0;
  display: block;
}
.loginPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc select, .registerPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc select {
  justify-content: left;
  text-align: left;
  position: relative;
  background-color: var(--inputColor);
}
.loginPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc .inputCalc, .registerPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc .inputCalc {
  gap: 0.5rem;
  padding: 1rem;
  background: var(--inputColor);
  border-radius: 10px;
}
.loginPageCalc .containerCalc .formDivCalc .formCalc .btnCalc, .registerPageCalc .containerCalc .formDivCalc .formCalc .btnCalc {
  padding: 0.8rem;
  background: var(--primaryColor);
  border-radius: 10px;
  color: var(--whiteColor);
  align-items: center;
  margin: auto;
  justify-content: center;
  font-size: 16px;
  width: 100%;
  gap: 0.5rem;
  transition: 0.3s ease;
  cursor: pointer;
  border: none;
}
.loginPageCalc .containerCalc .formDivCalc .formCalc .btnCalc .iconCalc, .registerPageCalc .containerCalc .formDivCalc .formCalc .btnCalc .iconCalc {
  font-size: 25px;
  transition: 0.3s ease;
}
.loginPageCalc .containerCalc .formDivCalc .formCalc .btnCalc:hover, .registerPageCalc .containerCalc .formDivCalc .formCalc .btnCalc:hover {
  background: var(--hoverColor);
  color: var(--blackColor);
}
.loginPageCalc .containerCalc .formDivCalc .formCalc .btnCalc .iconCalc, .registerPageCalc .containerCalc .formDivCalc .formCalc .btnCalc .iconCalc {
  transform: translate(5px);
}
.loginPageCalc .containerCalc .formDivCalc .esqueceuSenhaCalc, .registerPageCalc .containerCalc .formDivCalc .esqueceuSenhaCalc {
  font-size: 13px;
  color: var(--blackColor);
  opacity: 0.7;
  text-align: center;
}
.loginPageCalc .containerCalc .formDivCalc .esqueceuSenhaCalc a, .registerPageCalc .containerCalc .formDivCalc .esqueceuSenhaCalc a {
  text-decoration: underline;
  cursor: pointer;
  opacity: 1;
}

.inputDivCalc .inputCalc input select {
  width: calc(100% - 30px);
}

select {
  border: none;
  text-decoration: none;
  max-width: 200px;
}

::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .loginPageCalc .containerCalc, .registerPageCalc .containerCalc {
    width: 90%;
  }
  .loginPageCalc .imageDivCalc, .registerPageCalc .imageDivCalc {
    width: 100%;
  }
  .loginPageCalc .imageDivCalc .textDivCalc .titleCalc, .registerPageCalc .imageDivCalc .textDivCalc .titleCalc {
    font-size: var(--h1FontSize);
  }
  .loginPageCalc .imageDivCalc .textDivCalc p, .registerPageCalc .imageDivCalc .textDivCalc p {
    font-size: var(--normalFontSize);
  }
  .loginPageCalc .imageDivCalc .footerDivCalc .btnCalc, .registerPageCalc .imageDivCalc .footerDivCalc .btnCalc {
    padding: 0.5rem 1rem;
    font-size: var(--smallFontSize);
  }
  .loginPageCalc .formDivCalc .headerDivCalc h3, .registerPageCalc .formDivCalc .headerDivCalc h3 {
    font-size: var(--h2FontSize);
  }
  .loginPageCalc .formDivCalc .formCalc .btnCalc, .registerPageCalc .formDivCalc .formCalc .btnCalc {
    padding: 0.5rem;
    font-size: var(--normalFontSize);
  }
  .loginPageCalc .formDivCalc .formCalc .inputDivCalc input, .registerPageCalc .formDivCalc .formCalc .inputDivCalc input {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .headingCalc {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
@media screen and (max-width: 918px) {
  .headingCalc {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
}
@media screen and (max-width: 990px) {
  .headingCalc {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
@media screen and (max-width: 602px) {
  .loginPageCalc .containerCalc, .registerPageCalc .containerCalc {
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 1.5rem;
  }
  .loginPageCalc .containerCalc .imageDivCalc, .registerPageCalc .containerCalc .imageDivCalc {
    width: 100%;
  }
  .loginPageCalc .containerCalc .imageDivCalc .textDivCalc .titleCalc, .registerPageCalc .containerCalc .imageDivCalc .textDivCalc .titleCalc {
    font-size: 20px;
  }
  .loginPageCalc .containerCalc .imageDivCalc .textDivCalc p, .registerPageCalc .containerCalc .imageDivCalc .textDivCalc p {
    padding: 1rem;
  }
  .loginPageCalc .containerCalc .imageDivCalc .footerDivCalc .textCalc, .registerPageCalc .containerCalc .imageDivCalc .footerDivCalc .textCalc {
    font-size: 12px;
  }
  .loginPageCalc .containerCalc .imageDivCalc .footerDivCalc .btnCalc, .registerPageCalc .containerCalc .imageDivCalc .footerDivCalc .btnCalc {
    padding: 0.4rem 1.5rem;
  }
  .loginPageCalc .containerCalc .formDivCalc, .registerPageCalc .containerCalc .formDivCalc {
    gap: 1rem;
    transform: translateY(-0.5rem);
  }
  .loginPageCalc .containerCalc .formDivCalc .headerDivCalc, .registerPageCalc .containerCalc .formDivCalc .headerDivCalc {
    padding: 1rem 0;
  }
  .loginPageCalc .containerCalc .formDivCalc .headerDivCalc img, .registerPageCalc .containerCalc .formDivCalc .headerDivCalc img {
    width: 40px;
  }
  .loginPageCalc .containerCalc .formDivCalc .headerDivCalc h3, .registerPageCalc .containerCalc .formDivCalc .headerDivCalc h3 {
    font-size: 20;
  }
  .loginPageCalc .containerCalc .formDivCalc .formCalc, .registerPageCalc .containerCalc .formDivCalc .formCalc {
    gap: 0.5rem;
  }
  .loginPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc input, .registerPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc input {
    width: 100%;
  }
}
@media screen and (max-width: 678px) {
  .loginPageCalc .iconCalcB, .registerPageCalc .iconCalcB {
    position: absolute;
  }
  .loginPageCalc .containerCalc a, .registerPageCalc .containerCalc a {
    margin-right: 0;
  }
  .loginPageCalc .containerCalc .imageDivCalc .textDivCalc .titleCalc, .registerPageCalc .containerCalc .imageDivCalc .textDivCalc .titleCalc {
    font-size: 25px;
  }
  .loginPageCalc .containerCalc .imageDivCalc .textDivCalc p, .registerPageCalc .containerCalc .imageDivCalc .textDivCalc p {
    padding: 1rem;
  }
  .loginPageCalc .containerCalc .imageDivCalc .footerDivCalc .textCalc, .registerPageCalc .containerCalc .imageDivCalc .footerDivCalc .textCalc {
    font-size: 12px;
  }
  .loginPageCalc .containerCalc .imageDivCalc .footerDivCalc .btnCalc, .registerPageCalc .containerCalc .imageDivCalc .footerDivCalc .btnCalc {
    padding: 0.8rem 1.5rem;
  }
  .loginPageCalc .containerCalc .formDivCalc, .registerPageCalc .containerCalc .formDivCalc {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loginPageCalc .containerCalc .formDivCalc label, .registerPageCalc .containerCalc .formDivCalc label {
    text-align: center;
  }
  .loginPageCalc .containerCalc .formDivCalc .headerDivCalc, .registerPageCalc .containerCalc .formDivCalc .headerDivCalc {
    padding: 1.5rem 0;
  }
  .loginPageCalc .containerCalc .formDivCalc .headerDivCalc h3, .registerPageCalc .containerCalc .formDivCalc .headerDivCalc h3 {
    font-size: 20;
  }
  .loginPageCalc .containerCalc .formDivCalc .formCalc .btnCalc, .registerPageCalc .containerCalc .formDivCalc .formCalc .btnCalc {
    padding: 0.8rem 1.5rem;
  }
  .loginPageCalc .containerCalc .formDivCalc .formCalc span, .registerPageCalc .containerCalc .formDivCalc .formCalc span {
    text-align: center;
    justify-content: center;
  }
  .loginPageCalc .containerCalc .formDivCalc .formCalc .showMessage, .registerPageCalc .containerCalc .formDivCalc .formCalc .showMessage {
    max-width: 330px;
    text-align: center;
  }
  .loginPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc, .registerPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc {
    display: block;
  }
  .loginPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc input, .registerPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc input {
    width: 100%;
    max-width: 200px;
  }
  .loginPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc select, .registerPageCalc .containerCalc .formDivCalc .formCalc .inputDivCalc select {
    width: 100%;
    max-width: 300px;
    align-items: center;
  }
}
@media screen and (max-width: 927px) {
  .loginPageCalc .containerCalc, .registerPageCalc .containerCalc {
    width: 85%;
  }
}
hr {
  border: 0px;
  border-top: 1px solid lightgray;
}

.modal-container {
  width: 60vw;
  position: fixed;
  top: 200px;
  display: none;
  z-index: 999;
  justify-content: center;
}

.modal {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  width: 50%;
}

.active {
  display: flex;
}

.active .modal {
  animation: modal 0.4s;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translate3d(0, -60px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
span {
  margin: 10px auto;
}

.btns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.btns button {
  font-size: medium;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.btnOK {
  background-color: var(--primaryColor);
}

.btnClose {
  background-color: var(--secundaryColor);
}

@media (max-width: 500px) {
  .modal {
    padding: 20px;
    width: 60vw;
  }
  span, .btns button {
    font-size: smaller;
  }
}/*# sourceMappingURL=calculadora.css.map */