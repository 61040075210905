:root{
    --primaryColor: hsl(129, 53%, 46%);
    --secundaryColor: hsl(210, 79%, 26%);
    --hoverColor: hsl(128, 73%, 66%);
    --paleGreen: hsl(116, 65%, 79%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --greyText: rgb(190, 190, 190);
    --bgColor: hsl(220, 10%, 94%);
    --inputColor: hsl(330, 12%, 97%);
    --textColor: hsl(240, 1%, 48%);
   


    --biggestFontSize: 2.5rem;
    --h1FontSize: 1.5rem;
    --h2FontSize: 1.25rem;
    --h3FontSize: 1rem;
    --normalFontSize: .938rem;
    --smallFontSize: .813rem;
    --smallestFontSize: .75rem;



}
.input-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.inputDestaque{
    color: var(--primaryColor);
    
    

}

.inputBorder{
    border: 1px solid var(--primaryColor);
}

.inputCalc{
    
    justify-content: center;
    
    overflow: hidden;
    input{
        font-size: var(--h3FontSize);
        font-weight: 800;
        text-align: center;
        overflow: hidden;
        display: block;
        
    }
    p{
        font-size: var(--h3FontSize);
        font-weight: 800;
        text-align: center;
        overflow: hidden;
        display: block;
    }


    
}

.loginPageCalc, .registerPageCalc{
    height: 100%;
    width: 100%;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--secundaryColor);
    //box-shadow: 0 7px 50px rgb(214, 223, 213);

    .iconCalcB{
        font-size: 40px;
        transition: .3s ease;
        //transform: translate(5px);
        float: right;
        padding-right: .3rem;
        color: var(--secundaryColor);
        right: 0;
            top: 0;
            display: flex;
            position: absolute;
        
    }
    .containerCalc{
        height: 100%;
        width: 60%;
        margin: auto;
        justify-content: space-between;
        border-radius: 10px;
        background: hsl(0, 0%, 91%);
overflow: hidden;
       


        .imageDivCalc{
        position: relative;
        display: flex;
        flex-basis: 50%;
        width: 70%;
        height: 100%;
        padding: 0 1.5rem;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        border-radius: 10px;
        overflow: hidden;
    
        img, .footerDivCalc {
            position: absolute;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            
    
        }
        .textDivCalc{
            position: relative;
    
            .titleCalc{
                color:var(--whiteColor);
                font-size: 35px;
                font-weight: 800;
            }
            p{
                color: var(--whiteColor);
                padding: 1rem;
                font-weight: 400;
            }
        }
    
        .footerDivCalc{
            bottom: 10px;
            padding: 0 1rem;
            height: 60px;
            background: rgba(3, 67, 120, 0.070);
            left: 10px;
            right: 10px;
            justify-content: space-between;
            backdrop-filter: blur(1px);
            border-radius: 10px;
    
            .textCalc{
                color: var(--greyText);
            }
            .btnCalc{
                background: var(--whiteColor);
                color: var(--primaryColor);
                padding: .8rem 1.5rem;
                border-radius: 10px;
                font-weight: 400;
                transition: .3s ease;
                cursor: pointer;
    
                &:hover{
                    background: var(--hoverColor);
                   // color: var(--blackColor);
                    cursor: pointer;
    
                }
            }
    
        }
        }

        .formDivCalc{
            flex-basis: 50%;
            margin: auto;
            flex-direction: column;
            gap: 1.5rem;
            transform: translateY(-1rem);

            .headerDivCalc{
                text-align: center;
                padding: 1.5rem 0;

                img{
                    width: 90px;
                    padding-bottom: .5rem;
                }

                h3{
                    font-size: 25px;
                    color: var(--blackColor);
                    font-weight: 700;

                }
            }

            .formCalc{
                gap: 1rem;

                .inputDivCalc{
                    
                    
                    .iconCalc{
                        color: var(--greyText);
                    }
                    input{
                        background: none;
                        outline: none;
                        border: none;
                        width: 300px;
                        
                    }
                    label{
                        color: var(--blackColor);
                        font-weight: 400;
                        font-size: 14px;
                        padding: .5rem 0;
                        display: block;

                    }
                    select{
                        justify-content: left;
                        text-align: left;
                        position: relative;
                        background-color: var(--inputColor);
                    }
                   

                    .inputCalc{
                        gap: .5rem;
                        padding: 1rem;
                        background: var(--inputColor);
                        border-radius: 10px;
                    }
                }

                .btnCalc{
                    padding: .8rem;
                    background: var(--primaryColor);
                    border-radius: 10px;
                    color: var(--whiteColor);
                    align-items: center;
                    margin: auto;
                    justify-content: center;
                    font-size: 16px;
                    width: 100%;
                    gap: .5rem;
                    transition: .3s ease;
                    cursor: pointer;
                    border: none;

                    .iconCalc{
                        font-size: 25px;
                        transition: .3s ease;
                        
                    }

                    &:hover{
                        background: var(--hoverColor);
                        color: var(--blackColor);
                    }

                    .iconCalc{
                        //color: var(--blackColor);
                        transform: translate(5px);
                    }
                    

                }
            }
            

            .esqueceuSenhaCalc{
                font-size: 13px;
                color: var(--blackColor);
                opacity: .7;
                text-align: center;

                a{
                    text-decoration: underline;
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }

    }
}
.inputDivCalc .inputCalc input select {
    width: calc(100% - 30px);  
    
}
select{
    border: none;
    text-decoration: none;
    max-width: 200px;
}

///Scroll Hide//////

::-webkit-scrollbar{
    display: none;
}

@media (max-width: 768px) {
    .loginPageCalc, .registerPageCalc {
        .containerCalc {
            width: 90%;
        }

        .imageDivCalc {
            width: 100%;

            .textDivCalc .titleCalc {
                font-size: var(--h1FontSize);
            }

            .textDivCalc p {
                font-size: var(--normalFontSize);
            }

            .footerDivCalc .btnCalc {
                padding: .5rem 1rem;
                font-size: var(--smallFontSize);
            }
        }

        .formDivCalc {
            .headerDivCalc h3 {
                font-size: var(--h2FontSize);
            }

            .formCalc .btnCalc {
                padding: .5rem;
                font-size: var(--normalFontSize);
            }

            .formCalc .inputDivCalc input {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .headingCalc{
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    
}
@media screen and (max-width: 918px) {
    .headingCalc{
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }
    
}
@media screen and (max-width: 990px) {
    .headingCalc{
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    
}

// Queries Login e Registro

@media screen and (max-width: 602px) {
    .loginPageCalc, .registerPageCalc{
        .containerCalc{
            height: 100%;
            width: 100%;
            flex-direction: column;
            padding: 1.5rem;

            .imageDivCalc{
                width: 100%;
                .textDivCalc{
                    .titleCalc{
                        font-size: 20px;
                    }
                    p{
                        padding: 1rem;
                    }
                }

                .footerDivCalc{
                    .textCalc{
                        font-size: 12px;
                    }

                    .btnCalc{
                        padding: .4rem 1.5rem;
                    }
                    
                }
            }

            .formDivCalc{
                gap: 1rem;
                transform: translateY(-.5rem);

                .headerDivCalc{
                    padding: 1rem 0;

                    img{
                        width: 40px;
                    }
                    h3{
                        font-size: 20;
                    }
                }

                .formCalc{
                    gap: .5rem;

                    .inputDivCalc{
                        input{
                            width: 100%;
                        }
                    }
                }
            }


        }
        
    }
    
}


@media screen and (max-width: 678px) {
    .loginPageCalc, .registerPageCalc{
        
        .iconCalcB{
            //font-size: 30px;
            //right: 0;
            //top: 0;
            
            position: absolute;
            //padding-right: .1rem;
        }
        .containerCalc{
            a{
                margin-right: 0;
                
            }
           .imageDivCalc{

            
                
                .textDivCalc{
                    .titleCalc{
                        font-size: 25px;
                    }
                    p{
                        padding: 1rem;
                    }
                }

                .footerDivCalc{
                    .textCalc{
                        font-size: 12px;
                    }

                    .btnCalc{
                        padding: .8rem 1.5rem;
                    }
                    
                }
            }

            .formDivCalc{
                display: flex;
                justify-content: center;
                align-items: center;

                label{
                    text-align: center;
                }
             
                .headerDivCalc{
                    padding: 1.5rem 0;

                    h3{
                        font-size: 20;
                    }
                }

                .formCalc{

                    .btnCalc{
                        padding: .8rem 1.5rem;
                    }

                    span{
                        text-align: center;
                        justify-content: center;
                    }
                    .showMessage{
                        max-width: 330px;
                        text-align: center;
                        
                        

                    }

                    .inputDivCalc{
                        display: block;
                        input{
                            width: 100%;
                            max-width: 200px;
                        }
                        
                            select{
                                width: 100%;
                                max-width: 300px;
                                align-items: center;
                        }
                        
                    }
                }
            }


        }
        
    }
    
}

@media screen and (max-width: 927px) {
    .loginPageCalc, .registerPageCalc{
        .containerCalc{
            width: 85%;


        }
        
    }
    
}

hr {
    border: 0px;
    border-top: 1px solid lightgray;
  }
  
  .modal-container {
    width: 60vw;
    
    position: fixed;
    top: 200px;
    display: none;
    z-index: 999;
    justify-content: center;
  }
  
  .modal {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    width: 50%;
  }
  
  .active {
    display: flex;
  }
  
  .active .modal {
    animation: modal .4s;
  }
  
  @keyframes modal {
    from {
      opacity: 0;
      transform: translate3d(0, -60px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  span {
    margin: 10px auto;
  }
  
  .btns {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .btns button {
    font-size: medium;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
  
  .btnOK {
   background-color: var(--primaryColor);
  }
  
  .btnClose {
    background-color: var(--secundaryColor);
   }
  
   @media (max-width: 500px) {
    .modal {
      padding: 20px;
      width: 60vw;
    }   
  
    span, .btns button {
      font-size: smaller;
    }
   }