
body {
    max-width: 100%;
    overflow-x: hidden;
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
    
    
  }

.box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.column{
    flex-direction: column;
}

.row{
    flex-direction: row;
}



/**/
#header{
    width: 100%;
    display: flex;
    flex-direction:column-reverse;
    justify-content: space-between;
    align-items: center;
    
    
}

#header .logo{
    
    font-weight: bold;
}

.header .link{
    position: relative;
    margin: 12px;
    padding-bottom: 3px;
    text-decoration: none;
    transition: all .8s;
}



.header .link::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 3px;
    background-color: var(--hoverColor);
    transition: width .50s;
    
    border-bottom: 100px;
    
    
    
    
    outline: none;
    border: none;
    border-radius: 2rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    
}

.header .link:hover::before{
    width: 100%;
    color: aqua;
}