:root {
  --primaryColor: hsl(129, 53%, 46%);
  --secundaryColor: hsl(210, 79%, 26%);
  --hoverColor: hsl(128, 73%, 66%);
  --paleGreen: hsl(116, 65%, 79%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --greyText: rgb(190, 190, 190);
  --bgColor: hsl(220, 10%, 94%);
  --inputColor: hsl(330, 12%, 97%);
  --textColor: hsl(240, 1%, 48%);
  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontSize: .938rem;
  --smallFontSize: .813rem;
  --smallestFontSize: .75rem;
}

.topSection .headerSection {
  justify-content: right;
}
.topSection .headerSection h1 {
  font-size: var(--h2FontSize);
  color: var(--blackColor);
  font-weight: 700;
  text-align: right;
}
.topSection .headerSection p {
  font-size: var(--normalFontSize);
  color: var(--textColor);
  font-weight: 500;
}
.topSection .searchBar {
  padding: 0.5rem 2rem;
  background: var(--whiteColor);
  border-radius: 5px;
  gap: 1rem;
  box-shadow: 0 2px 4px var(--inputColor);
}
.topSection .searchBar input {
  border: none;
  outline: none;
  background: none;
}
.topSection .searchBar .icon {
  color: var(--textColor);
}
.topSection .searchBar .icon:hover {
  color: var(--primaryColor);
}
.topSection .adminDiv {
  gap: 0.1rem;
}
.topSection .adminDiv .icon {
  font-size: var(--biggestFontSize);
  background: var(--bgColor);
  border-radius: 5px;
  padding: 7px;
  box-shadow: 0 2px 4px var(--inputColor);
  color: var(--textColor);
}/*# sourceMappingURL=top.css.map */