.mainContent {
  width: 87%;
  height: 100%;
  padding: 1rem;
  overflow: auto;
}
.mainContent .bottom {
  margin-top: 2rem;
  margin-left: 2rem;
  gap: 2rem;
  align-items: flex-start;
}/*# sourceMappingURL=body.css.map */